import * as S from "./styled";
import React from "react";
import { i18n } from "../../translate/i18n";
import c1 from "./img/6.jpeg";
import c2 from "./img/1.jpeg";
import c3 from "./img/2.png";
import c4 from "./img/3.jpeg";
import c5 from "./img/4.jpeg";
import c6 from "./img/8.png";
import c7 from "./img/7.png";
import c10 from "./img/12.avif";
import c11 from "./img/11.jpeg";
import c8 from "./img/dio.png";
import c21 from "./img/21.jpeg";
import c20 from "./img/brasilprev.jpeg";
import c28 from "./img/20.jpeg";
import c22 from "./img/devsmais40.webp";
import c23 from "./img/9.jpeg";
import c24 from "./img/unnamed.png";
import c25 from "./img/studywisetech_logo.jpeg";
import c26 from "./img/kadabra_school_logo.jpeg";
import c30 from "./img/agora1.png";
import c31 from "./img/agora2.png";
import c32 from "./img/agora3.png";
import Animation from "./animation/animation.gif";
import { ParagrafoCentral, TituloCentral } from "../../theme/padrao";
import { useLayoutEffect, useRef } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import CountUp from 'react-countup';

const parcerias = [
  { img: c30, alt: "Sensedia", titulo: "Sensedia" },
  { img: c31, alt: "RoboCore", titulo: "RoboCore" },
  { img: c32, alt: "TripleTen", titulo: "TripleTen" },
  { img: c28, alt: "EBAC", titulo: "E.B.A.C" },
  { img: c21, alt: "CPBR15", titulo: "CPBR15" },
  { img: c1, alt: "Elas Programam", titulo: "Elas Programam" },
  { img: c8, alt: "DIO", titulo: "DIO" },
  { img: c2, alt: "Devaria", titulo: "Devaria" },
  { img: c3, alt: "Alura", titulo: "Alura" },
  { img: c10, alt: "Teatro Santander", titulo: "Teatro Santander" },
];

const parcerias2 = [
  { img: c11, alt: "RoadSec", titulo: "RoadSec" },
  { img: c4, alt: "Mergo", titulo: "Mergo" },
  { img: c5, alt: "Caneca do Dev", titulo: "Caneca do Dev" },
  { img: c6, alt: "Frontin Sampa", titulo: "Frontin Sampa" },
  { img: c7, alt: "Womakers Code", titulo: "Womakers Code" },
  { img: c20, alt: "Brasilprev", titulo: "Brasilprev" },
  { img: c22, alt: "Evento Devs +40", titulo: "Evento Devs +40" },
  { img: c23, alt: "UseScript", titulo: "UseScript" },
  { img: c24, alt: "FIAP", titulo: "FIAP" },
  { img: c25, alt: "StudyWise", titulo: "StudyWise" },
  { img: c26, alt: "IFTL", titulo: "IFTL" },
];

export function Parcerias() {
  const el = useRef();
  const tl = useRef();

  useLayoutEffect(() => {
    gsap.registerPlugin(ScrollTrigger);
    const ctx = gsap.context(() => {
      tl.current = gsap
        .timeline({
          scrollTrigger: {
            trigger: ".models-item",
            scrub: true,
            // markers: true,
            start: "top 900px",
            end: "bottom 700px",
          },
        })
        .fromTo(
          "#model-1",
          {
            opacity: 0,
            y: 160,
          },
          {
            opacity: 1,
            y: 0,
          }
        )
        .fromTo(
          "#model-2",
          {
            opacity: 0,
            y: 160,
          },
          {
            opacity: 1,
            y: 0,
          }
        )
        .fromTo(
          "#model-3",
          {
            opacity: 0,
            y: 160,
          },
          {
            opacity: 1,
            y: 0,
          }
        );
    }, el);

    return () => {
      gsap.killTweensOf(".models-item");
    };
  }, []);
  return (
    <S.ContainerGeral>
    <TituloCentral>{i18n.t("servicos.titulo")}</TituloCentral>
      <ParagrafoCentral>{i18n.t("servicos.paragrafo")}</ParagrafoCentral>
      <S.CardsRow>
        <S.CardsStyled>
          {/* DEIXAR EM COLUNA */}
          <h1>
      <CountUp end={24700} duration={3.5} />
      k
    </h1>
          <S.Titulo>Instagram</S.Titulo>
          <S.Paragrafo>
            Com média de 1.000 view no stories e 6% de engajamento
          </S.Paragrafo>
        </S.CardsStyled>
        <S.CardsStyled> 
        <h1>
      <CountUp end={58000} duration={3.5} />
      k
    </h1>
          <S.Titulo>Linkedin</S.Titulo>
          <S.Paragrafo>
            Incluindo 2.250.831k view anual e 100.000k views por mês de publicações
          </S.Paragrafo>
        </S.CardsStyled>
        <S.CardsStyled className="models-item" id="model-3">
          <h1>
      <CountUp end={1700} duration={3.5} />
      k
    </h1>
          <S.Titulo>YouTube</S.Titulo>
          <S.Paragrafo>
            Incluindo 20.524k view total e 700k views por mes
          </S.Paragrafo>
        </S.CardsStyled>
      </S.CardsRow>
      <S.CardsRow>
        <S.Demogr>
<S.Titulo>Audiência / Demografia</S.Titulo>
<S.Paragrafo>89% <strong>Brasil</strong>, 11% demais paises</S.Paragrafo>
<S.Paragrafo>45% com idade entre <strong>25 a 34 anos</strong>, 22% entre <strong>35 a 54 anos</strong>, 33% demais idades</S.Paragrafo>

<S.Titulo>Público-Alvo</S.Titulo>
<S.Paragrafo>
    Pessoas interessadas em aprender <strong>programação</strong>, acompanhar rotinas de estudo, receber diversas <strong>dicas</strong> para otimizar o aprendizado e curiosos que desejam explorar o universo da <strong>tecnologia</strong>. 
</S.Paragrafo>

        </S.Demogr>
        <S.ImageDemog src={Animation} alt="EBAC" />
      </S.CardsRow>
       <TituloCentral>{i18n.t("parceria.titulo")}</TituloCentral>
      <ParagrafoCentral>{i18n.t("parceria.paragrafo")}</ParagrafoCentral>
      <S.Coluna1 className="models-content" ref={el}>
        <S.Centro1 className="models-item" id="model-1">
          <h6>{i18n.t("servicos.servico1")}</h6>
        </S.Centro1>
        <S.Centro1 className="models-item" id="model-2">
          <h6>{i18n.t("servicos.servico2")}</h6>
        </S.Centro1>
        <S.Centro1 className="models-item" id="model-3">
          <h6>{i18n.t("servicos.servico3")}</h6>
        </S.Centro1>
      </S.Coluna1>
      <S.Coluna>
      <div className="slider">
        {parcerias2.map((parceria, index) => (
          <S.Centro key={index}>
          <div>
            <img src={parceria.img} alt={parceria.alt} />
            <S.ParagrafoCardParcerias>{parceria.titulo}</S.ParagrafoCardParcerias>
            </div>
          </S.Centro>
        ))}
      </div>
      </S.Coluna>
      <S.Coluna>
      <div className="slider">
        {parcerias.map((parceria, index) => (
          <S.Centro key={index}>
          <div>
            <img src={parceria.img} alt={parceria.alt} />
            <S.ParagrafoCardParcerias>{parceria.titulo}</S.ParagrafoCardParcerias>
            </div>
          </S.Centro>
        ))}
      </div>
      </S.Coluna>

      <ParagrafoCentral>{i18n.t("parceria.mais")}</ParagrafoCentral>
    </S.ContainerGeral>
  );
}
