import * as S from "./styled";
import Blog from "./assets/blog.svg";
import logo from "./assets/sheldon_cooper.webp";
import arrow from "./../../assets/icons/icon-arrow.svg";
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";
import { HeaderComponentExterno } from "../HeaderComponentExterno";

export function Links() {
  const firebaseConfig = {
    apiKey: "AIzaSyC-9CmDnG2veP-jwKtePnnzyo_XInI_QdY",
    authDomain: "portfoliotechju.firebaseapp.com",
    projectId: "portfoliotechju",
    storageBucket: "portfoliotechju.appspot.com",
    messagingSenderId: "307798887837",
    appId: "1:307798887837:web:af4fa0ca6ec8451342f374",
    measurementId: "G-VJVZTMP8NT",
  };

  const app = initializeApp(firebaseConfig);
  const analytics = getAnalytics(app);

  const handleClickInst = () => {
    logEvent(analytics, "Instagram");
  };

  const handleClickgithub = () => {
    logEvent(analytics, "GitHub");
  };

  const handleClickyoutube = () => {
    logEvent(analytics, "YouTube");
  };

  const handleClicklinkedin = () => {
    logEvent(analytics, "Linkedin");
  };

  const handleClickQuiz = () => {
    logEvent(analytics, "Quiz");
  };

  const handleClicktwitch = () => {
    logEvent(analytics, "Twitch");
  };

  const handleClickparceria = () => {
    logEvent(analytics, "FIAP");
  };

  const handleClickpix = () => {
    logEvent(analytics, "PIX");
  };

  const handleClickparceria2 = () => {
    logEvent(analytics, "CURSO 1");
  };

  const handleClickparceria3 = () => {
    logEvent(analytics, "ebook fiap");
  };

  const handleClickparceria4 = () => {
    logEvent(analytics, "meetup fiap");
  };

   const handleClickparceria5 = () => {
    logEvent(analytics, "Votação");
  };

  

  return (
    <>
      <HeaderComponentExterno />
      <S.Header>
        <header>
          <img src={logo} alt="logo techjuliana" />
          <ul class="social-links1">
            <li>
              <a
                href="https://github.com/techjuliana"
                target="_blank"
                rel="noreferrer"
                onClick={handleClickgithub}
                class="fab fa-github"
              />
            </li>
            <li>
              <a
                href=" https://www.twitch.tv/techjuliana"
                target="_blank"
                rel="noreferrer"
                onClick={handleClicktwitch}
                class="fab fa-twitch"
              />
            </li>
            <li>
              <a
                href="https://www.instagram.com/tech.juliana"
                target="_blank"
                rel="noreferrer"
                onClick={handleClickInst}
                class="fab fa-instagram"
              />
            </li>
            <li>
              <a
                href="https://www.linkedin.com/in/techjuliana"
                target="_blank"
                rel="noreferrer"
                onClick={handleClicklinkedin}
                class="fab fa-linkedin-in"
              />
            </li>
            <li>
              <a
                href="https://youtube.com/channel/UCb912uf3zcQO8eDl34HpLNQ"
                target="_blank"
                rel="noreferrer"
                onClick={handleClickyoutube}
                class="fab fa-youtube"
              />
            </li>
          </ul>
        </header>

        <section>
          <h2>Compartilhe esse link com os amigos :)</h2>
          {/* <S.Lista>
            <S.Resposta>
              <S.Pergunta> */}
                {/* {i18n.t("questions.pergunta1.titulo")} */}
                {/* Parcerias e Descontos \0/ */}
                {/* <img src={arrow} alt="arrow" /> */}
              {/* </S.Pergunta> */}
              <a
                href="https://fiap.me/julianatech"
                target="_blank"
                rel="noreferrer"
                onClick={handleClickparceria}
              >
                <article>
                  <h2> Vestibular FIAP 2025 </h2>
                </article>
              </a>
              <a
                href="https://fiap.me/4h0AVAI"
                target="_blank"
                rel="noreferrer"
                onClick={handleClickparceria3}
              >
                <article>
                  {/* <img src={Blog} alt="" width="28px" height="28px" /> */}
                  <h2>  GUIA DE CARREIRA | Ebook gratuito </h2>
                </article>
              </a>
              <a
                href="https://www.alertpix.live/techjuliana"
                target="_blank"
                rel="noreferrer"
                onClick={handleClickpix}
              >
                <article>
                  {/* <img src={Blog} alt="" width="28px" height="28px" /> */}
                  <h2> Que tal um Café? </h2>
                </article>
              </a>
                   <a
                href="https://www.meetup.com/pt-BR/fiapmeetups/"
                target="_blank"
                rel="noreferrer"
                onClick={handleClickparceria4}
              >
                <article>
                  <h2>Meetups gratuitos - online e presencial em SP</h2>
                </article>
              </a>

                   <a
                href="https://stars.github.com/nominate/"
                target="_blank"
                rel="noreferrer"
                onClick={handleClickparceria5}
              >
                <article>
                  <h2>[Votação] Me ajude a se tornar uma star no GitHub para levar mais ainda conteúdos para vocês @techjuliana
</h2>
                </article>
              </a>

          <S.Lista>
            <S.Resposta>
              <S.Pergunta>
                {/* {i18n.t("questions.pergunta1.titulo")} */}
                Links mencionados para te ajudar a chegar no próximo nível \0/
                <img src={arrow} alt="arrow" />
              </S.Pergunta>
              <a
                href=" https://quiztech-omega.vercel.app/"
                onClick={handleClickQuiz}
                target="_blank"
                rel="noreferrer"
              >
                <article>
                  {/* <img src={Blog} alt="" width="28px" height="28px" /> */}
                  <h2>Quiz - Bora ver se é um dev bom mesmo?</h2>
                </article>
              </a>
              <a
                href="https://www.cambly.com/invite/PEZ96JX8?st=040824&sc=4"
                target="_blank"
                rel="noreferrer"
              >
                <article>
                  <h2 className="text">
                    Cambly - Estudo ingles por aqui -> Ganhe 10 min de aula com
                    o link ^^
                  </h2>
                </article>
              </a>
              <a
                href="https://www.topcoder.com/thrive/tracks?track=Competitive%20Programming"
                target="_blank"
                rel="noreferrer"
              >
                <article>
                  <h2 className="text">COMPETITIVE PROGRAMMING</h2>
                </article>
              </a>

              <a
                href="https://cake.day/mobile"
                target="_blank"
                rel="noreferrer"
              >
                <article>
                  <h2 className="text">
                    Inglês gratuito - treino por aqui tambem ^^
                  </h2>
                </article>
              </a>
            </S.Resposta>
          </S.Lista>

          <S.Lista>
            <S.Resposta>
              <S.Pergunta>
                {/* {i18n.t("questions.pergunta1.titulo")} */}
                Diversos cursos Gratuitos
                <img src={arrow} alt="arrow" />
              </S.Pergunta>

              <a
                href="https://www.freecodecamp.org/"
                target="_blank"
                rel="noreferrer"
              >
                <article>
                  <h2 className="text">FreeCodeCamp</h2>
                </article>
              </a>
              <a
                href="https://www.udacity.com/catalog/all/free/any-school/any-skill/any-difficulty/any-duration/any-type/most-popular/page-1"
                target="_blank"
                rel="noreferrer"
              >
                <article>
                  <h2 className="text">Udacity - Gratuitos</h2>
                </article>
              </a>
              <a href="https://www.edx.org/" target="_blank" rel="noreferrer">
                <article>
                  <h2 className="text">Edx</h2>
                </article>
              </a>
              <a
                href="https://cursos-livres.descomplica.com.br/cursos-gratis"
                target="_blank"
                rel="noreferrer"
              >
                <article>
                  <h2 className="text">Cursos gratuitos - Descomplica</h2>
                </article>
              </a>
              <a href="https://www.ev.org.br/" target="_blank" rel="noreferrer">
                <article>
                  <h2 className="text">Fundação Bradesco</h2>
                </article>
              </a>
              <a
                href="https://www.harvardonline.harvard.edu/course/data-science-principles?&utm_source=google&utm_medium=cpc&utm_campaign=dsry_intl&gad_source=1&gclid=Cj0KCQiAwbitBhDIARIsABfFYIJXNfpLcld9JItS481JlO9NcBQf9Cgun8bEP7pMAcJqcNFFTDIMu_EaAq6TEALw_wcB"
                target="_blank"
                rel="noreferrer"
              >
                <article>
                  <h2 className="text">Harvard Online</h2>
                </article>
              </a>
              <a
                href="https://online.stanford.edu/explore?filter%5B0%5D=free_or_paid%3Afree&keywords=&items_per_page=12&page=1"
                target="_blank"
                rel="noreferrer"
              >
                <article>
                  <h2 className="text">Stanford</h2>
                </article>
              </a>
              <a
                href="https://www.dio.me/sign-up?ref=FP2E8ZWG5G"
                target="_blank"
                rel="noreferrer"
              >
                <article>
                  <h2 className="text">Dio</h2>
                </article>
              </a>
            </S.Resposta>
          </S.Lista>

          <S.Lista>
            <S.Resposta>
              <S.Pergunta>
                {/* {i18n.t("questions.pergunta1.titulo")} */}
                Exercicios/atividades de Programação
                <img src={arrow} alt="arrow" />
              </S.Pergunta>
              <a
                href="https://www.codewars.com/"
                target="_blank"
                rel="noreferrer"
              >
                <article>
                  <h2 className="text">Codewars</h2>
                </article>
              </a>
              <a
                href="https://edabit.com/challenges"
                target="_blank"
                rel="noreferrer"
              >
                <article>
                  <h2 className="text">Edabit</h2>
                </article>
              </a>
              <a href="https://exercism.org/" target="_blank" rel="noreferrer">
                <article>
                  <h2 className="text">Exercism</h2>
                </article>
              </a>
              <a
                href="https://judge.beecrowd.com/pt/login?redirect=%2Fpt"
                target="_blank"
                rel="noreferrer"
              >
                <article>
                  <h2 className="text">Beecrowd</h2>
                </article>
              </a>
            </S.Resposta>
          </S.Lista>

          <S.Lista>
            <S.Resposta>
              <S.Pergunta>
                {/* {i18n.t("questions.pergunta1.titulo")} */}
                Aplicativos de Programação e Estudo - GRATUITOS
                <img src={arrow} alt="arrow" />
              </S.Pergunta>
              <a
                href="https://mimo.org/web/build"
                target="_blank"
                rel="noreferrer"
              >
                <article>
                  <h2 className="text">Mimo Exercicios</h2>
                </article>
              </a>
              <a
                href="https://play.google.com/store/apps/details?id=com.ryzac.codecademygo&hl=pt_BR&gl=US"
                target="_blank"
                rel="noreferrer"
              >
                <article>
                  <h2 className="text">Codecademy Go</h2>
                </article>
              </a>
            </S.Resposta>
          </S.Lista>
        </section>
      </S.Header>
    </>
  );
}
